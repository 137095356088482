function mapChange ()
{
  if (document.querySelector('.contacts__address-item') && document.querySelector('.contacts__address-item').dataset.addressLink) {
    const offices = document.querySelectorAll('.contacts__address-item');
    offices.forEach(el =>
    {
      el.addEventListener('mouseover', setUrl);
    });
    function setUrl (e)
    {
      const mapFrame = document.querySelector('.contacts__map iframe');
      if (mapFrame.src !== e.currentTarget.dataset.addressLink) {
        mapFrame.src = e.currentTarget.dataset.addressLink;
      }
    }
  }
}

function maskingInputs ()
{
  let formFlags = {
    name: false,
    tel: false,
    email: false,
    message: false,
  };

  let form = document.querySelector('.contacts__form');
  let inputName = form.querySelector('#contact_name');
  let inputTel = form.querySelector('#contact_tel');
  let inputEmail = form.querySelector('#contact_email');
  let inputMessage = form.querySelector('#contact_message');
  let btnSubmit = form.querySelector('.contacts__form-btn[type="submit"]');

  let nameMask = new Inputmask({
    mask: 'Aa{2,80} [Aa{2,80}] [Aa{2,80}]',
    oncomplete: () =>
    {
      formFlags.name = true;
      checkForm();
    },
    onincomplete: () =>
    {
      formFlags.name = false;
      checkForm();
    }
  });

  let telMask = new Inputmask({
    mask: '+99 (999) 999-99-99',
    greedy: true,
    oncomplete: () =>
    {
      formFlags.tel = true;
      checkForm();
    },
    onincomplete: () =>
    {
      formFlags.tel = false;
      checkForm();
    }
  });

  let emailMask = new Inputmask({
    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,8}][.*{1,8}]",
    greedy: false,
    definitions: {
      '*': {
        validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~\-]",
        cardinality: 1,
        casing: "lower"
      }
    },
    oncomplete: () =>
    {
      formFlags.email = true;
      checkForm();
    },
    onincomplete: () =>
    {
      formFlags.email = false;
      checkForm();
    }
  });

  function messageMask (e)
  {
    if (e.target.value.length > 10) {
      formFlags.message = true;
      checkForm();
    } else {
      formFlags.message = false;
      checkForm();
    }
  };

  function checkForm ()
  {
    if (formFlags.name && formFlags.tel && formFlags.email && formFlags.message) {
      btnSubmit.removeAttribute('disabled');
    } else {
      btnSubmit.setAttribute('disabled', 'disabled');
    }
  };

  if (form) {
    nameMask.mask(inputName);
    telMask.mask(inputTel);
    emailMask.mask(inputEmail);
    inputMessage.addEventListener('input', messageMask);
  }

}
maskingInputs();